<template>
  <div class="form-layer" v-if="isVisible">
    <div class="form">
      <div class="form-content">
        <slot name="hide-trigger"> </slot>

        <slot></slot>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.form-layer {
  @apply w-full h-screen fixed top-0 left-0 z-40;
  @apply bg-gray-900 bg-opacity-80;
}

.form {
  @apply fixed w-full top-0 left-0 min-h-screen;

  .form-content {
    @apply z-30 bg-white shadow w-full max-w-sm p-4 min-h-screen max-h-screen overflow-y-auto bottom-0 right-0 absolute;

    label {
      @apply mb-1;
    }

    input[type="text"],
    textarea {
      @apply block  max-w-full min-w-full;
    }
  }

  .form__trigger-hide {
    @apply absolute top-0 right-0 z-10;
  }
}
</style>

<script>
export default {
  props: {
    isVisible: {
      type: Boolean,
      default: false,
    },
  },
  name: "ItemForm",
};
</script>